<template>
  <div style="height:100%;">
    <el-container class="ContainerBox">
      <el-header   class="IndexHeader">
        <el-row justify="space-between">
          <el-col :span="2">
            <div class="Index">首页</div>
          </el-col>
          <el-col :span="2">
            <div class="Square" @click="ToZone()">广场</div>
          </el-col>
          <el-col :span="2">
            <div class="Active" @click="ToEvents">活动</div>
          </el-col>
          <el-col :span="2">
            <div class="QA" @click="ToMainQA">Q&A</div>
          </el-col>
          <el-col :span="2">
            <div class="Play" @click="ToPlay">摸鱼</div>
          </el-col>
          <el-col :span="2" :offset="8">
            <div class="Avatar">
              <div class="UserAvatar">
                <img :src="usericon" @click="toAccount">
              </div>
              <div class="UserInfoPanel">
                  <div class="UserNickName">
                    {{nickname}}
                  </div>
                  <div class="item-Operation">
                    <el-row class="PanelItem">
                      <a class="Account-Center">
                        <div class="account-link">
                          <i class="el-icon-user-solid"></i>
                          <span style="padding:0 0.1rem" @click="toAccount">个人中心</span>
                        </div>
                        <div class="item-arrow"><i class="el-icon-arrow-right"></i></div>
                      </a>
                    </el-row>
                    <el-row class="PanelItem">
                      <a class="Account-Center">
                        <div class="account-link">
                          <i class="el-icon-s-home"></i>
                          <span style="padding:0 0.1rem" @click="ToSpace">我的空间</span>
                        </div>
                        <div class="item-arrow"><i class="el-icon-arrow-right"></i></div>
                      </a>
                    </el-row>
                    <el-row class="PanelItem">
                      <a class="Account-Center">
                        <div class="account-link">
                          <i class="el-icon-s-order"></i>
                          <span style="padding:0 0.1rem" @click="ToMp">内容管理</span>
                        </div>
                        <div class="item-arrow"><i class="el-icon-arrow-right"></i></div>
                      </a>
                    </el-row>
                  </div>
                  <div class="Logout">
                      <el-row>
                      <a class="Account-Logout">
                        <div class="account-link">
                          <i class="el-icon-switch-button"></i>
                          <span style="padding:0 0.1rem" @click="Logout()">退出登录</span>
                        </div>
                      </a>
                    </el-row>
                  </div> 
              </div>
            </div>
          </el-col>
          <el-col :span="1">
            <div class="Message">消息</div>
          </el-col>
          <el-col :span="4"><div class=""></div></el-col>
        </el-row>
      </el-header>
      <el-main class="ActiveContent">
        <div class="ActiveList">
          <el-row class="ActiveTitle">
            <el-col :span="6">
              <div class="ActiveItemTitleInfo">活动标题</div>
            </el-col>
            <el-col :span="2">
              <div class="ActiveItemTitleInfo">报名费用</div>
            </el-col>
            <el-col :span="3">
              <div class="ActiveItemTitleInfo">活动期数</div>
            </el-col>
            <el-col :span="3">
              <div class="ActiveItemTitleInfo">持续天数</div>
            </el-col>
            <el-col :span="6">
              <div class="ActiveItemTitleInfo">开始时间</div>
            </el-col>
            <el-col :span="4">
              <div class="ActiveItemTitleInfo">操作</div>
            </el-col>
          </el-row>
          <el-row v-for="(item,index) in allactivelist" :key="index" class="ActiveBanner">
            <el-col :span="6">
              <div class="ActiveItemTitleInfo">{{item.title}}</div>
            </el-col>
            <el-col :span="2">
              <div class="ActiveItemTitleInfo">{{item.fee}}</div>
            </el-col>
            <el-col :span="3">
              <div class="ActiveItemTitleInfo">{{item.epoch}}</div>
            </el-col>
            <el-col :span="3">
              <div class="ActiveItemTitleInfo">{{item.duration}}</div>
            </el-col>
            <el-col :span="6">
              <div class="ActiveItemTitleInfo">{{item.startDate}}</div>
            </el-col>
            <el-col :span="4">
              <div class="ActiveItemTitleInfo_Opera" @click="ViewDetails(item.id)">查看详情</div>
            </el-col>
          </el-row>
        </div>
        <div class="Logs">
          <div class="LogsTitle">
            更新日志
          </div>
          <div class="LogsContent" v-for="(item,index) in logsData" :key="index">
              <div class="LogsName">{{item.events}}</div>
              <div class="LogsTime">{{item.time}}</div>
          </div>
        </div>
      </el-main>
      <div class="CodeBox">
        <CodeEditor />
        <div class="CodeBar">
          <i class="iconfont icon-jiantou_xiangyouliangci RightBtn"></i>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
import {GetUserNickName} from "@/api/user.js"
import {GetAllActiveList} from "@/api/active.js"
import '../assets/rem.js'
import CodeEditor from './CodeEditor.vue'
export default {
    name:'Index',
    components:{
      CodeEditor
    },
    data(){
      return{
        code:'',
        nickname:'',
        usericon:'',
        allactivelist:'',
        logsData:[
          {
            'time':'2022.10.27-19.06',
            'events':'修复了一些已知bug'
          },
          {
            'time':'2022.9.06-23.30',
            'events':'修复了一些已知bug'
          },
          {
            'time':'2022.8.27-13.30',
            'events':'完善评论功能'
          },
          {
            'time':'2022.8.20-23.20',
            'events':'用户可编辑文章发布'
          },
          {
            'time':'2022.8.09-21.50',
            'events':'用户打卡时选择性填写目标的完成详情信息'
          },
          {
            'time':'2022.8.09-19.41',
            'events':'可上传本地图片修改头像'
          }
        ]
      }
    },
    created(){
      GetUserNickName().then(res=>{
        this.nickname = res.data.nickname
        this.usericon = res.data.icon
        window.localStorage.setItem('userid',res.data.id)
        window.localStorage.setItem('usericon',res.data.icon)
        window.localStorage.setItem('username',res.data.nickname)
      }),
      this.GetActiveList()
    },
    methods:{
      toAccount(){
        this.$router.push({path:'/account'})
      },
      GetActiveList(){
        GetAllActiveList().then(res=>{
          this.allactivelist = res.data.list
        })
      },
      ViewDetails(id){
        this.$router.push({
          path:`/activedetails/${id}`
        })
      },
      Logout(){
        localStorage.clear()
        this.$router.push({
          path:'/'
        })
      },
      ToZone(){
        this.$router.push({path:'/zone'})
      },
      ToMp(){
        this.$router.push({path:'/mp_blog'})
      },
      ToSpace(){
        this.$router.push({path:`/myspace`})
      },
      ToEvents(){
        this.$router.push({path:'/events'})
      },
      ToMainQA(){
        this.$router.push({path:'/mainqa'})
      },
      isShow(){
        $('.RightBtn').click(function(e){
          if($('.CodeBox').offset().left == 0){
            $('.CodeBox').animate({left:'-615px'},500)
          }else{
            $('.CodeBox').animate({left:'0px'},500)
          }
        })
      },
      ToPlay(){
        this.$router.push({path:`/play`})
      }
    },
    mounted(){
      this.isShow()
    }
}
</script>

<style scoped>
  .ContainerBox{
    /* overflow: hidden; */
    height: 100%;
  }
.IndexHeader{
  line-height: 0.6rem;
  width: 100%;
  box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
  position: sticky;
  top: 0;
  background: #ffffff;
  padding: 0;
}
.Index , .Square , .QA ,.Active,.Avatar,.Message,.Play{
  height: 100%;
  font-size: 0.16rem;
  cursor: pointer;
}
.el-avatar--circle{
  position: relative;
  transition: all  .25s  ease-in;
  margin-top: 10px;
  z-index: 10;
}
.Index:hover ,.Active:hover, .Square:hover , .QA:hover ,.Message:hover,.Play:hover{
  background: #dddddd;
}
.Avatar{
  position: relative;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.UserAvatar{
  position: relative;
  margin-top: 0.05rem;
  width: 50px;
  height: 50px;
  z-index: 10;
}
.UserAvatar img{
  width: 100%;
  height: 100%;
  border-radius: 30px;
}
.Avatar:hover .UserAvatar img{
  margin-top: 30px;
  transform:scale(1.5);
}
.Avatar:hover .UserInfoPanel{
  margin-top: 10px;
  display: block;
}
.UserInfoPanel{
  top: 70px;
  display: none;
  width: 3rem;
  height: 4rem;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow:rgb(211, 209, 209) 0px 0px 10px;
  z-index: 1;
}
.UserNickName{
  padding: 20px 0 0 0 ;
  color: rgb(251, 114, 153)
}
.item-Operation{
  padding: 90px 0px 60px 0;
  width: 90%;
  margin: 0 auto;
}
.PanelItem:hover{
  background-color: #d9d9d9;
  border-radius: 0.06rem;
}
.Account-Center{
  display: flex;
  justify-content: space-around;
}
.account-link{
  display: flex;
  align-items: center;
  height: 40px;
}
.item-arrow{
  height: 40px;
  line-height: 40px;
}
.Logout{
  width: 65%;
  margin: 0 auto;
  height: 40px;
  margin-bottom: 0px;
  text-align: left;
  border-top: 1px solid gray;
}
.Account-Logout{
  display: flex;
}
.ActiveContent{
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.ActiveContent::-webkit-scrollbar{
  display: none;
}
.ActiveList{
  flex: 0 0 50%;
  border-radius: 0.04rem;
  box-sizing: border-box;
  background-color: white;
  padding: 0 0.05rem;
}
.ActiveTitle{
  width: 100%;
  height: 40px;
  background-color: #f6f6f6;
  border-radius: 4px 4px 0 0;
}
.ActiveItemTitleInfo{
  height: 0.4rem;
  line-height: 0.4rem;
  font-size: 0.14rem;
}
.ActiveItemTitleInfo_Opera{
  height: 0.4rem;
  line-height: 0.4rem;
  font-size: 0.14rem;
  cursor: pointer;
}
.ActiveBanner{
  width: 100%;
  height: 40px;
  background-color: #d9d9d9;
  margin-bottom: 0.05rem;
  border-radius: 0.04rem;
}
.ActiveBanner:hover{
  background-color: #dddddd;
}
.Logs{
  flex: 0 0 50%;
  background-color: rgb(247 247 247);
  height: auto;
  overflow: auto;
  border-radius: 0.04rem;
  box-sizing: border-box;
  padding: 0 0.05rem;
  margin-left: 0.05rem;
}
.LogsTitle{
  font-size: 20px;
  line-height: 40px;
  overflow: auto;
}
.Logs::-webkit-scrollbar{
  display: none;
}
.LogsTitle::-webkit-scrollbar{
  display: none;
}
.LogsContent{
  display: flex;
  background-color: #d9d9d9;
  margin-bottom: 0.05rem;
  border-radius: 0.04rem;
}
.LogsName{
  padding: 0.1rem 0.2rem;
  flex: 1;
  font-size: 14px;
  text-align: left;
}
.LogsTime{
  flex: 0 0 40%;
  font-size: 14px;
  line-height: 0.4rem;
}

.CodeBox{
  position: absolute;
  width: 630px;
  height: 350px;
  display: flex;
  top: 30%;
  left: -615px;
}
.CodeBar{
  width: 15px;
}
.icon-jiantou_xiangyouliangci{
  color: #787878;
  line-height: 330px;
  font-size: 30px !important;
  cursor: pointer;
}
</style>